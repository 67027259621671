import React, {Suspense} from 'react';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';

import ErrorPanel from '../widgets/error-panel.jsx';
import LoadSpinner from '../widgets/load-spinner.jsx';
import SideNavbar from '../widgets/side-navbar.jsx';
import TopicCommonView from './topic-common-view.jsx';

const AccountSettingsView = React.lazy(_ => import('./account-settings-view.jsx'));
import ContactsView from './contacts-view.jsx';
import  AddFrdReqList from './new-frd-req-list.jsx'
import  MyFriends from './my-friends.jsx'
import  MyGroup from './my-group.jsx'
const CreateAccountView = React.lazy(_ => import('./create-account-view.jsx'));
import AccNotificationsView from './acc-notifications-view.jsx';
import AccSecurityView from './acc-security-view.jsx';
import AccSupportView from './acc-support-view.jsx';
import LoginView from './login-view.jsx';
import NewTopicView from './new-topic-view.jsx';

const PasswordResetView = React.lazy(_ => import('./password-reset-view.jsx'));
import SettingsView from './settings-view.jsx';
import ValidationView from './validation-view.jsx';
import AddFriendView from "./addFriend.jsx";

// Panel titles for translation.
const messages = defineMessages({
    login: {
        id: 'sidepanel_title_login',
        description: 'Sidepanel title for LoginView.',
        defaultMessage: 'Sign In'
    },
    register: {
        id: 'sidepanel_title_register',
        description: 'Sidepanel title for CreateAccountView.',
        defaultMessage: 'Create Account'
    },
    settings: {
        id: 'sidepanel_title_settings',
        description: 'Sidepanel title for SettingsView.',
        defaultMessage: 'Settings'
    },
    edit: {
        id: 'sidepanel_title_account_settings',
        description: 'Sidepanel title for AccountSettingsView.',
        defaultMessage: 'Account Settings'
    },
    general: {
        id: 'panel_title_general',
        description: 'Title for TopicCommon.',
        defaultMessage: 'General'
    },
    security: {
        id: 'panel_title_security',
        description: 'Title for TopicSecirity and AccSecurity.',
        defaultMessage: 'Security'
    },
    crop: {
        id: 'panel_title_crop',
        description: 'Title for AvatarCropView.',
        defaultMessage: 'Drag to Adjust'
    },
    notif: {
        id: 'sidepanel_title_acc_notifications',
        description: 'Sidepanel title for AccNotificationsView.',
        defaultMessage: 'Notifications'
    },
    support: {
        id: 'sidepanel_title_acc_support',
        description: 'Sidepanel title for AccSupportView.',
        defaultMessage: 'Support'
    },
    newtpk: {
        id: 'sidepanel_title_newtpk',
        description: 'Sidepanel title for NewTopicView.',
        defaultMessage: 'Start New Chat'
    },
    newFrdReqList: {
        translation:'好友申请列表',
        id: 'sidepanel_title_add_frd_req_list',
        description: 'Sidepanel title for NewFriend.',
        defaultMessage: '好友申请列表'
    },
    myFriends: {
        translation:'我的好友',
        id: 'sidepanel_title_my_friends',
        description: 'Sidepanel title for MyFriend.',
        defaultMessage: '我的好友'
    },
    myGroup: {
        translation:'我的群组',
        id: 'sidepanel_title_my_group',
        description: 'Sidepanel title for MyFriend.',
        defaultMessage: '我的群组'
    },
    cred: {
        id: 'sidepanel_title_cred',
        description: 'Sidepanel title for ValidationView.',
        defaultMessage: 'Confirm Credentials'
    },
    reset: {
        id: 'sidepanel_title_reset',
        description: 'Sidepanel title for PasswordResetView.',
        defaultMessage: 'Reset Password'
    },
    archive: {
        id: 'sidepanel_title_archive',
        description: 'Sidepanel title for ContactsView-Archive.',
        defaultMessage: 'Archived Chats'
    },
    blocked: {
        id: 'sidepanel_title_blocked',
        description: 'Sidepanel title for ContactsView-Blocked.',
        defaultMessage: 'Blocked Chats'
    },
    addFriend: {
        id: 'sidepanel_title_addFriend',
        description: 'Sidepanel title for CreateAccountView.',
        defaultMessage: 'Create Account'
    },
});

class SidepanelView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            topic: '',
            avatar: '',
            name: '',
        };

        this.handleNewTopic = this.handleNewTopic.bind(this);
        this.handleAddFriend = this.handleAddFriend.bind(this);
        this.handleNewFrdReqList = this.handleNewFrdReqList.bind(this);
        this.showContextMenu = this.showContextMenu.bind(this);


    }

    handleAddFriend(topicName, index, avatar, name) {
        console.log("handleAddFriend name:" + name)
        this.props.topicName = topicName
        this.props.avatar = avatar
        this.props.name = name
        this.state.topicName = topicName
        this.state.avatar = avatar
        this.state.name = name

    }

    handleNewTopic(e) {
        this.showContextMenu({ topicName: "", y: e.pageY, x: e.pageX });

       // this.props.onNavigate('newtpk');
    }

    showContextMenu(params) {
        console.log("handleContextMenu params:",params)
        // const {formatMessage} = this.props.intl;
        // const topic = this.props.tinode.getTopic(params.topicName);
        // if (!topic) {
        //     return;
        // }

        const menuItems = [
            {
                title: "新的好友/群组",
                handler:  (params, errorHandler) => {
                    this.props.onNavigate('newtpk');
                    // console.log("params",params)
                    // this.handleDoAddFriend(params.user,1)
                    // console.log("agree1 params:",params)
                }
            },
            {
                title: "好友申请列表",
                handler:  (params, errorHandler) => {
                    this.props.onNavigate('newFrdReqList');
                }
            },
            {
                title: "我的好友",
                handler:  (params, errorHandler) => {
                    this.props.onNavigate('myFriends');
                }
            },
            {
                title: "我的群组",
                handler:  (params, errorHandler) => {
                    this.props.onNavigate('myGroup');
                }
            },
        ];


        this.props.showContextMenu({
            topicName: this.props.topic,
            x: params.x,
            y: params.y,
            user: params.topicName}, menuItems);
    }

    handleNewFrdReqList() {
        this.props.onNavigate('newFrdReqList');
    }


    render() {
        const {formatMessage} = this.props.intl;
        const view = this.props.state || (this.props.myUserId ? 'contacts' : 'login');

        let title, avatar, badges;
        if (view == 'contacts') {
            title = this.props.title;
            avatar = this.props.avatar ? this.props.avatar : true;
            badges = this.props.trustedBadges;
        } else {
            console.log("view:",messages[view])
            title = formatMessage(messages[view]);
            avatar = false;
            badges = null;
        }

        let onCancel;
        if (['login', 'contacts'].indexOf(view) == -1) {
            onCancel = this.props.onCancel;
        }

        return (
            <div id="sidepanel">
                <SideNavbar
                    state={view}
                    title={title}
                    avatar={avatar}
                    tinode={this.props.tinode}
                    trustedBadges={badges}
                    myUserId={this.props.myUserId}
                    onSignUp={this.props.onSignUp}
                    onSettings={this.props.onSettings}
                    onNewTopic={this.handleNewTopic}
                    onNewFrdReqList={this.handleNewFrdReqList}
                    onCancel={onCancel}/>

                <ErrorPanel
                    level={this.props.errorLevel}
                    text={this.props.errorText}
                    action={this.props.errorAction}
                    actionText={this.props.errorActionText}
                    onClearError={this.props.onError}/>

                <LoadSpinner show={this.props.loadSpinnerVisible}/>

                {view === 'login' ?
                    <LoginView
                        login={this.props.login}
                        disabled={this.props.loginDisabled}
                        persist={this.props.persist}
                        onLogin={this.props.onLoginRequest}
                        onPersistenceChange={this.props.onPersistenceChange}/> :

                    view === 'register' ?
                        <Suspense fallback={<div className="panel-form-row"><FormattedMessage id="loading_note"
                                                                                              defaultMessage="Loading..."
                                                                                              description="Message shown when component is loading"/>
                        </div>}>
                            <CreateAccountView
                                tinode={this.props.tinode}
                                reqCredMethod={this.props.reqCredMethod}
                                onShowCountrySelector={this.props.onShowCountrySelector}
                                onCreateAccount={this.props.onCreateAccount}
                                onCancel={this.props.onCancel}
                                onError={this.props.onError}/>
                        </Suspense> :

                        view === 'settings' ?
                            <SettingsView
                                transport={this.props.transport}
                                serverAddress={this.props.serverAddress}
                                secureConnection={this.props.secureConnection}
                                onCancel={this.props.onCancel}
                                onUpdate={this.props.onGlobalSettings}/> :

                            view === 'edit' ?
                                <Suspense fallback={<div className="panel-form-row"><FormattedMessage id="loading_note"
                                                                                                      defaultMessage="Loading..."
                                                                                                      description="Message shown when component is loading"/>
                                </div>}>
                                    <AccountSettingsView
                                        tinode={this.props.tinode}
                                        myUserId={this.props.myUserId}
                                        trustedBadges={this.props.trustedBadges}
                                        reqCredMethod={this.props.reqCredMethod}
                                        onShowCountrySelector={this.props.onShowCountrySelector}
                                        onNavigate={this.props.onNavigate}
                                        onCredAdd={this.props.onCredAdd}
                                        onCredDelete={this.props.onCredDelete}
                                        onCredConfirm={this.props.onCredConfirm}
                                        onError={this.props.onError}/>
                                </Suspense> :

                                (view === 'general' || view === 'crop') ?
                                    <TopicCommonView
                                        topic="me"
                                        tinode={this.props.tinode}
                                        myUserId={this.props.myUserId}
                                        reqCredMethod={this.props.reqCredMethod}
                                        onUpdateTopicDesc={this.props.onUpdateAccountDesc}
                                        onUpdateTagsRequest={this.props.onUpdateAccountTags}
                                        onError={this.props.onError}/> :

                                    view === 'notif' ?
                                        <AccNotificationsView
                                            messageSounds={this.props.messageSounds}
                                            desktopAlerts={this.props.desktopAlerts}
                                            desktopAlertsEnabled={this.props.desktopAlertsEnabled}
                                            incognitoMode={this.props.incognitoMode}
                                            onTogglePushNotifications={this.props.onTogglePushNotifications}
                                            onToggleMessageSounds={this.props.onToggleMessageSounds}
                                            onToggleIncognitoMode={this.props.onToggleIncognitoMode}/> :

                                        view === 'security' ?
                                            <AccSecurityView
                                                tinode={this.props.tinode}
                                                onUpdateAccountDesc={this.props.onUpdateAccountDesc}
                                                onUpdatePassword={this.props.onUpdatePassword}
                                                onLogout={this.props.onLogout}
                                                onDeleteAccount={this.props.onDeleteAccount}
                                                onShowAlert={this.props.onShowAlert}
                                                onShowBlocked={this.props.onShowBlocked}/> :

                                            view === 'support' ?
                                                <AccSupportView
                                                    serverAddress={this.props.serverAddress}
                                                    serverVersion={this.props.serverVersion}/> :

                                                (view === 'contacts' || view == 'archive' || view == 'blocked') ?
                                                    <ContactsView
                                                        tinode={this.props.tinode}
                                                        myUserId={this.props.myUserId}
                                                        connected={this.props.connected}
                                                        topicSelected={this.props.topicSelected}
                                                        archive={view == 'archive'}
                                                        blocked={view == 'blocked'}
                                                        chatList={this.props.chatList}
                                                        showContextMenu={this.props.showContextMenu}
                                                        onTopicSelected={this.props.onTopicSelected}
                                                        onShowArchive={this.props.onShowArchive}/> :

                                                    view === 'newtpk' ?
                                                        <NewTopicView
                                                            tinode={this.props.tinode}
                                                            searchResults={this.props.searchResults}
                                                            onInitFind={this.props.onInitFind}
                                                            onSearchContacts={this.props.onSearchContacts}
                                                            onAddFriend={this.handleAddFriend}
                                                            onCreateTopic={this.props.onCreateTopic}
                                                            onError={this.props.onError}/> :
                                                        view === 'newFrdReqList' ?
                                                            <Suspense fallback={<div className="panel-form-row">
                                                                <FormattedMessage id="loading_note"
                                                                                  defaultMessage="Loading..."
                                                                                  description="Message shown when component is loading"/>
                                                            </div>}>
                                                                <AddFrdReqList
                                                                    tinode={this.props.tinode}
                                                                    myUserId={this.props.myUserId}
                                                                    connected={this.props.connected}
                                                                    topicSelected={this.props.topicSelected}
                                                                    archive={view == 'archive'}
                                                                    blocked={view == 'blocked'}
                                                                    chatList={this.props.chatList}
                                                                    onStartTopicRequest = {this.props.onStartTopicRequest}
                                                                    showContextMenu={this.props.showContextMenu}
                                                                    onTopicSelected={this.props.onTopicSelected}
                                                                    onShowArchive={this.props.onShowArchive}/> :

                                                            </Suspense> :
                                                            view === 'myFriends' ?
                                                                <Suspense fallback={<div className="panel-form-row">
                                                                    <FormattedMessage id="loading_note"
                                                                                      defaultMessage="Loading..."
                                                                                      description="Message shown when component is loading"/>
                                                                </div>}>
                                                                    <MyFriends
                                                                        tinode={this.props.tinode}
                                                                        myUserId={this.props.myUserId}
                                                                        connected={this.props.connected}
                                                                        topicSelected={this.props.topicSelected}
                                                                        archive={view == 'archive'}
                                                                        blocked={view == 'blocked'}
                                                                        chatList={this.props.chatList}
                                                                        showContextMenu={this.props.showContextMenu}
                                                                        onTopicSelected={this.props.onTopicSelected}
                                                                        onCreateTopic={this.props.onCreateTopic}
                                                                        onShowArchive={this.props.onShowArchive}/> :

                                                                </Suspense> :
                                                                view === 'myGroup' ?
                                                                    <Suspense fallback={<div className="panel-form-row">
                                                                        <FormattedMessage id="loading_note"
                                                                                          defaultMessage="Loading..."
                                                                                          description="Message shown when component is loading"/>
                                                                    </div>}>
                                                                        <MyGroup
                                                                            tinode={this.props.tinode}
                                                                            myUserId={this.props.myUserId}
                                                                            connected={this.props.connected}
                                                                            topicSelected={this.props.topicSelected}
                                                                            archive={view == 'archive'}
                                                                            blocked={view == 'blocked'}
                                                                            chatList={this.props.chatList}
                                                                            showContextMenu={this.props.showContextMenu}
                                                                            onTopicSelected={this.props.onTopicSelected}
                                                                            onCreateTopic={this.props.onCreateTopic}
                                                                            onShowArchive={this.props.onShowArchive}/> :

                                                                    </Suspense> :

                                                            view === 'newFrdReqList' ?
                                                                <Suspense fallback={<div className="panel-form-row">
                                                                    <FormattedMessage id="loading_note"
                                                                                      defaultMessage="Loading..."
                                                                                      description="Message shown when component is loading"/>
                                                                </div>}>
                                                                    <AddFrdReqList
                                                                        tinode={this.props.tinode}
                                                                        myUserId={this.props.myUserId}
                                                                        connected={this.props.connected}
                                                                        topicSelected={this.props.topicSelected}
                                                                        archive={view == 'archive'}
                                                                        blocked={view == 'blocked'}
                                                                        chatList={this.props.chatList}
                                                                        showContextMenu={this.props.showContextMenu}
                                                                        onTopicSelected={this.props.onTopicSelected}
                                                                        onShowArchive={this.props.onShowArchive}/> :
                                                                </Suspense> :

                                                            view === 'addFriend' ?
                                                                <Suspense fallback={<div className="panel-form-row">
                                                                    <FormattedMessage id="loading_note"
                                                                                      defaultMessage="Loading..."
                                                                                      description="Message shown when component is loading"/>
                                                                </div>}>
                                                                    <AddFriendView
                                                                        tinode={this.props.tinode}
                                                                        myUserId={this.props.myUserId}
                                                                        trustedBadges={this.props.trustedBadges}
                                                                        reqCredMethod={this.props.reqCredMethod}
                                                                        onShowCountrySelector={this.props.onShowCountrySelector}
                                                                        onNavigate={this.props.onNavigate}
                                                                        onCredAdd={this.props.onCredAdd}
                                                                        onCredDelete={this.props.onCredDelete}
                                                                        onCredConfirm={this.props.onCredConfirm}
                                                                        topic={this.state.topicName}
                                                                        name={this.state.name}
                                                                        avatar={this.state.avatar}
                                                                        onError={this.props.onError}/>
                                                                </Suspense> :

                                                                view === 'cred' ?
                                                                    <ValidationView
                                                                        credCode={this.props.credCode}
                                                                        credMethod={this.props.credMethod}
                                                                        credToken={this.props.credToken}
                                                                        onSubmit={this.props.onValidateCredentials}
                                                                        onCancel={this.props.onCancel}/> :

                                                                    view === 'reset' ?
                                                                        <Suspense
                                                                            fallback={<div className="panel-form-row">
                                                                                <FormattedMessage id="loading_note"
                                                                                                  defaultMessage="Loading..."
                                                                                                  description="Message shown when component is loading"/>
                                                                            </div>}>
                                                                            <PasswordResetView
                                                                                tinode={this.props.tinode}
                                                                                reqCredMethod={this.props.reqCredMethod}
                                                                                onShowCountrySelector={this.props.onShowCountrySelector}
                                                                                onRequest={this.props.onPasswordResetRequest}
                                                                                onReset={this.props.onResetPassword}
                                                                                onCancel={this.props.onCancel}
                                                                                onError={this.props.onError}/>
                                                                        </Suspense> :
                                                                        null}
            </div>
        );
    }
};

export default injectIntl(SidepanelView);
