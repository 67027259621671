// Edit account parameters.
import React from 'react';
import {FormattedMessage} from 'react-intl';


import AvatarUpload from '../widgets/avatar-upload.jsx';
import BadgeList from '../widgets/badge-list.jsx';

import {makeImageUrl} from '../lib/blob-helpers.js';
import {MAX_TITLE_LENGTH, MAX_TOPIC_DESCRIPTION_LENGTH} from '../config.js';
import CredentialEdit from '../widgets/credential-edit.jsx';


function _clip(str, length) {
    return str && str.substring(0, length);
}


export default class AccountSettingsView extends React.Component {
    constructor(props) {
        super(props);
        console.log("---name:" + this.props.name)
        this.handleAddFriend = this.handleAddFriend.bind(this);
        this.handleChange = this.handleChange.bind(this);
        const me = this.props.tinode.getMeTopic();
        this.state = {
            fullName: _clip(me.public ? me.public.fn : undefined, MAX_TITLE_LENGTH),
            description: _clip(me.public ? me.public.note : undefined, MAX_TOPIC_DESCRIPTION_LENGTH),
            // avatar: makeImageUrl(me.public ? me.public.photo : null),
            credentials: me.getCredentials() || [],
            credEdit: undefined,
            msg: null,
            userName: null,
            userAvatar: null,
            userTopic: null,
        };
        this.state.userName = this.props.name;
        this.state.userAvatar = this.props.avatar;
        this.state.userTopic = this.props.topic;
        this.state.group = this.props.group;
        if (window.addFriend !== null && window.addFriend !== undefined) {
            this.state.userName = window.addFriend.name
            this.state.userAvatar = window.addFriend.avatar
            this.state.userTopic = window.addFriend.topic
            this.state.group = window.addFriend.group;
            //window.addFriend = null
        }
    }

    handleAddFriend() {
        console.log("handleAddFriend")
        const add = this.props.tinode.addFriend(this.state.userTopic, this.state.msg)
        alert("发送请求成功")

    }

    handleChange(e) {
        this.setState({msg: e.target.value});
    }

    componentDidMount() {
        const me = this.props.tinode.getMeTopic();
        me.onCredsUpdated = _ => this.setState({credentials: me.getCredentials()});
    }

    componentWillUnmount() {
        const me = this.props.tinode.getMeTopic();
        me.onCredsUpdated = null;
        window.addFriend = null;
    }


    render() {

        this.state.userName = this.props.name;
        this.state.userAvatar = this.props.avatar;
        this.state.userTopic = this.props.topic;
        this.state.group = this.props.group;
        if (window.addFriend !== null && window.addFriend !== undefined) {
            this.state.userName = window.addFriend.name
            this.state.userAvatar = window.addFriend.avatar
            this.state.userTopic = window.addFriend.topic
            this.state.group = window.addFriend.group;
        }
        let showAddFriend = true
        if (this.state.group != null) {
            let topic = this.props.tinode.getTopic(this.state.group)
            if (topic && topic.public) {
                if (topic.public.addFriendSwitch == 0) {
                    showAddFriend = false;
                }
            }
            for (let i = 0; i < window.friends.length; i++) {
                let item = window.friends[i];
                if (this.state.userTopic === item.userId) {
                    showAddFriend = false;
                }
            }
            console.log("topic:", topic)
        }
        //this.setState(this.state)
        console.log("window.addFriend:", window.addFriend)
        console.log("this.state.userName:", this.state.userName)
        console.log("this.state.userName:", this.state.avatar)
        return (
            <div className="scrollable-panel">
                <div className="panel-form-column">

                    <center>
                        <AvatarUpload
                            tinode={this.props.tinode}
                            avatar={this.state.userAvatar}
                            readOnly={true}
                            uid={this.state.userTopic}
                            title={this.state.userName}/>
                    </center>
                    <div className="group">
                        <label className="small">
                            <FormattedMessage id="label_your_name" defaultMessage="Your name"
                                              description="Label for full name editing"/>
                        </label>
                        <div className="large ellipsized">{this.state.userName}</div>
                    </div>


                </div>
                {showAddFriend ?
                    <div><FormattedMessage id="group_add_friend" defaultMessage="请输入消息内容"
                                           description="">{
                        (prompt) => <input type="text" placeholder="请输入消息内容"
                                           onChange={this.handleChange}
                        />
                    }</FormattedMessage>
                        <div className="dialog-buttons">
                            <button className="primary" onClick={this.handleAddFriend}>
                                <FormattedMessage id="button_add_friend" defaultMessage="添加好友"
                                                  description="Button [AddFriend]"/>
                            </button>
                        </div>
                    </div> : null}

            </div>
        );
    }
};
