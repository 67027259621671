/* ContactsView holds all contacts-related stuff */
import React from 'react';
import {FormattedMessage, defineMessages} from 'react-intl';

import ContactList from '../widgets/contact-list.jsx';

import {updateFavicon} from '../lib/utils.js';
import HashNavigation from "../lib/navigation";


export default class MyFriends extends React.Component {
    constructor(props) {
        super(props);

        this.handleAction = this.handleAction.bind(this);
        this.handleDelFriend = this.handleDelFriend.bind(this);
        this.handleShowContextMenu = this.handleShowContextMenu.bind(this);


        this.props.tinode.getMeta("me", {"what": "FrdsReq"}).then(ctrl => {
            // console.log("ctrl:", ctrl);
            let list = ctrl.friends;
            window.friends = list;
            if (list == null) {
                return
            }

            for (var i = 0; i < list.length; i++) {
                let item = list[i];
                var itemContact = {
                    public: item.public,
                    comment: item.content,
                    preview: item.content,
                    topic: item.userId,
                    topicName: item.userId,
                }
                this.state.contactList.push(itemContact)
            }
            this.setState(this.state);
            //this.state.list = addNewFrdReq;
            console.log("addNewFrdReq", this.state.contactList)
        }).catch(err => {
            console.error("Failed subscription to", this.state.topic, err);

        });
        this.state =
            {
                contactList: [],
            }
    }

    static deriveStateFromProps(props) {
        const contacts = [];
        let unreadThreads = 0;
        let archivedCount = 0;

        return {
            contactList: contacts,
            unreadThreads: unreadThreads
        };
    }


    componentDidUpdate(prevProps, prevState) {
        // if (prevProps.chatList != this.props.chatList ||
        //     prevProps.archive != this.props.archive ||
        //     prevProps.blocked != this.props.blocked) {
        //     const newState = NewFrdReqList.deriveStateFromProps(this.props);
        //     this.setState(newState);
        //     if (newState.unreadThreads != prevState.unreadThreads) {
        //         updateFavicon(newState.unreadThreads);
        //     }
        // }
    }


    handleShowContextMenu(params, menuItems) {

        menuItems = [
            {
                title: "发送消息",
                handler: this.handleAction
            },
            {
                title: "删除好友",
                handler: this.handleDelFriend
            },
        ]
        //console.log("handleShowContextMenu params:",params)
        this.props.showContextMenu({
            topicName: this.props.topic,
            x: params.x,
            y: params.y,
            user: params.topicName
        }, menuItems);
    }

    handleDelFriend(params) {
        let topicName = params.user
        let topic = this.props.tinode.getTopic(topicName)
        console.log("handleDelFriend params:", params)
        topic.delTopic(true,true);
    }

    handleAction(params) {
        console.log("handleAction params:", params)
        HashNavigation.navigateTo(HashNavigation.removeUrlParam(window.location.hash, 'tab'));
        this.props.onCreateTopic(params.user);
    }

    render() {
        return (
            <FormattedMessage id="contacts_not_found"
                              defaultMessage="You have no chats<br />¯∖_(ツ)_/¯"
                              description="HTML message shown in ContactList when no contacts are found">{
                (no_contacts) => <ContactList
                    tinode={this.props.tinode}
                    connected={this.props.connected}
                    contacts={this.state.contactList}
                    emptyListMessage={no_contacts}
                    topicSelected={this.props.topicSelected}
                    myUserId={this.props.myUserId}
                    showOnline={true}
                    showMode={false}
                    showContextMenu={this.handleShowContextMenu}
                    showUnread={false}
                    // onTopicSelected={this.handleAction}
                />

            }

            </FormattedMessage>

        );
    }
};
