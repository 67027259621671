// Forward Menu: message forwarding popup/dropdown menu.
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {Tinode} from 'tinode-sdk';

import ContactList from './contact-list.jsx';
import SearchContacts from './search-contacts.jsx';

export default class AtDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            query: null,
            showContacts: []
        };

        this.handleEscapeKey = this.handleEscapeKey.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.handleSearchContacts = this.handleSearchContacts.bind(this);
        this.handleContactSelected = this.handleContactSelected.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.getGroupMember = this.getGroupMember.bind(this);


    }

    componentDidMount() {
        this.props.onInitFind();
        this.getGroupMember();
    }

    handleEscapeKey(e) {
        if (e.keyCode === 27) {
            // Not forwarding the message.
            this.props.hide(false);
        }
    }

    handleClose(e) {
        e.preventDefault();
        this.props.hide(false);
    }

    handleSearchContacts(query) {
        this.setState({query: Tinode.isNullValue(query) ? null : query});
        this.props.onSearchContacts(query);
    }

    handleContactSelected(uid) {
        this.props.onTopicSelected(this.props.topic,uid);
        this.props.hide(true);

    }

    getGroupMember() {
        let contacts = []
        let topic = this.props.tinode.getTopic(this.props.topic)
        //console.log("--- topic:", topic)
        let users = topic._users
        //console.log("--- users:", users)
        contacts.push({
            topic:"all",
            public:{
                fn:"所有人"
            }
        })
        for (let key in users) {
            if (users.hasOwnProperty(key)) { // 确保属性是对象自身的而不是从原型链继承的
                contacts.push(users[key])
                //console.log(key, obj[key]);
            }
        }
        this.setState({showContacts: contacts})
    }

    handleSearch(param) {

        console.log("handleSearch param:", param)
        if (param.length > 0) {
            let topic = this.props.tinode.getTopic(this.props.topic)
            console.log("--- topic:", topic)
            let users = topic._users
            let showContacts = []

            for (let key in users) {
                if (users.hasOwnProperty(key)) {

                    let user = users[key];
                    console.log("handleSearch user:", user)
                    let isMatch = false
                    if (user.public != null && user.public.fn != null && user.public.fn.indexOf(param) !== -1) {
                        isMatch = true
                    }
                    if (user.private != null && user.private.comment != null && user.private.comment.indexOf(param) !==-1 ) {
                        isMatch = true
                    }
                    console.log("user.public.fn:" + user.public.fn + " isMatch:" + isMatch)
                    if (isMatch) {
                        showContacts.push(user)
                    }
                    //console.log(key, obj[key]);
                }
            }
            this.setState({showContacts: showContacts})
        } else {
            this.getGroupMember()
        }


    }

    render() {

        // users.map((value, key) => );
        return (
            <div className="alert-container">
                <div className="forward-dialog">
                    <div className="title with-control">
                        <div><FormattedMessage id="forward_to" defaultMessage="at_to"
                                               desription="Title of the contact selector dialog when forwarding a message"/>
                        </div>
                        <div><a href="#" onClick={this.handleClose}><i className="material-icons">close</i></a></div>
                    </div>
                    <FormattedMessage id="forward_to_search_placeholder" defaultMessage="Search contacts"
                                      description="Contact search prompt when forwarding a message.">{
                        (search_placeholder) => <SearchContacts
                            placeholder={search_placeholder}

                            onSearchContacts={this.handleSearch}/>
                    }</FormattedMessage>
                    <FormattedMessage id="search_no_results" defaultMessage="Search returned no results"
                                      description="Text shown in contacts view when query returned no results.">{
                        (not_found_placeholder) => <ContactList
                            tinode={this.props.tinode}
                            contacts={this.state.showContacts}
                            myUserId={this.props.myUserId}
                            emptyListMessage={not_found_placeholder}
                            showOnline={false}
                            showUnread={false}
                            showContextMenu={false}
                            onTopicSelected={this.handleContactSelected}/>
                    }</FormattedMessage>
                </div>
            </div>
        );
    }
}
