/* ContactsView holds all contacts-related stuff */
import React from 'react';
import {FormattedMessage, defineMessages} from 'react-intl';

import UserList from '../widgets/user-list.jsx';

import {updateFavicon} from '../lib/utils.js';
import HashNavigation from "../lib/navigation";


const messages1 = defineMessages({
    archived_contacts_title: {
        id: "archived_contacts",
        defaultMessage: "Archived contacts ({count})",
        description: "Label for archived chats"
    }
});

export default class NewFrdReqList extends React.Component {
    constructor(props) {
        super(props);

        this.handleAction = this.handleAction.bind(this);
        this.handleContextMenu = this.handleContextMenu.bind(this);
        this.handleDoAddFriend = this.handleDoAddFriend.bind(this);
        // this.props.tinode.getMeTopic()
        // this.props.tinode.getMeTopic().setMeta({userAddress: {address: "aaa", phone: "1112",name:"ccc"}, what: "userAddress"}).then(resp => {
        //     console.log("handleDoAddFriend resp:", resp)
        //
        //
        // })
        //     .catch(err => {
        //         console.log("err:", err)
        //     });
        this.props.tinode.getMeta("me", {"what": "addNewFrdReq"}).then(ctrl => {
            console.log("ctrl:", ctrl);
            let list = ctrl.addNewFrdReq;
            if(list!=null){
                for (var i = 0; i < list.length; i++) {
                    let item = list[i];
                    var itemContact = {
                        public: item.public,
                        comment: item.content,
                        preview: item.content,
                        topic: item.userId,
                        topicName: item.userId,
                        status:item.status,
                        createTime:item.createTime,
                    }
                    this.state.contactList.push(itemContact)
                }
                this.setState(this.state);
            }

            //this.state.list = addNewFrdReq;
            console.log("addNewFrdReq", this.state.contactList)
        }).catch(err => {
            console.error("Failed subscription to", this.state.topic, err);

        });
        this.state =
            {
                contactList: [],
            }
    }

    static deriveStateFromProps(props) {
        const contacts = [];
        let unreadThreads = 0;
        let archivedCount = 0;

        return {
            contactList: contacts,
            unreadThreads: unreadThreads
        };
    }


    componentDidUpdate(prevProps, prevState) {
        // if (prevProps.chatList != this.props.chatList ||
        //     prevProps.archive != this.props.archive ||
        //     prevProps.blocked != this.props.blocked) {
        //     const newState = NewFrdReqList.deriveStateFromProps(this.props);
        //     this.setState(newState);
        //     if (newState.unreadThreads != prevState.unreadThreads) {
        //         updateFavicon(newState.unreadThreads);
        //     }
        // }
    }

    handleContextMenu(params) {
        console.log("handleContextMenu params:", params)
        // const {formatMessage} = this.props.intl;
        const topic = this.props.tinode.getTopic(params.topicName);
        if (!topic) {
            return;
        }
        var item;
        for(var i=0;i<this.state.contactList.length;i++){
            var t = this.state.contactList[i];
            if(topic==t.topic){
                item = t;
            }
        }
        var  menuItems ;
        if(t!=null){
            if(t.status==2){
                menuItems = [];
            }else if(t.status==3){
                menuItems = [
                    {
                        title: "同意",
                        handler: (params, errorHandler) => {
                            console.log("params", params)
                            this.handleDoAddFriend(params.user, 2)
                            // console.log("agree1 params:",params)
                        }
                    }
                ];
            }else {
                menuItems = [
                    {
                        title: "同意",
                        handler: (params, errorHandler) => {
                            console.log("params", params)
                            this.handleDoAddFriend(params.user, 2)
                            // console.log("agree1 params:",params)
                        }
                    },
                    {
                        title: "忽略",
                        handler: (params, errorHandler) => {
                            // console.log("agree1 params:",params)
                            this.handleDoAddFriend(params.user, 3)
                        }
                    }
                ];
            }
        }



        this.props.showContextMenu({
            topicName: this.props.topic,
            x: params.x,
            y: params.y,
            user: params.topicName
        }, menuItems);
    }

    handleDoAddFriend(uid, status) {
        console.log("handleDoAddFriend uid:" + uid + "  status:" + status)
        const topic = this.props.tinode.getTopic(uid);
        if (topic) {



            topic.setMeta({addFrd: {status: status, uid: uid}, what: "addFrd"}).then(resp => {
                console.log("handleDoAddFriend resp:", resp)
                alert("添加成功")
                //this.props.tinode.leave(uid)
                HashNavigation.navigateTo(HashNavigation.removeUrlParam(window.location.hash, 'tab'));
                this.props.onStartTopicRequest(uid)
                this.props.tinode.subscribe(uid,{what:"desc"}).then(res=>{
                    console.log("aaa res:",res)
                    this.props.tinode.publish(uid,"我已通过你的好友请求",false).then(re1=>{
                        this.props.tinode.leave(uid)
                    })

                    //this.handleLoginSuccessful();
                })

            })
                .catch(err => {
                    console.log("err:", err)
                });
        }
    }

    handleAction(action_ignored) {
        this.props.onShowArchive();
    }

    render() {
        return (
            <FormattedMessage id="contacts_not_found"
                              defaultMessage="You have no chats<br />¯∖_(ツ)_/¯"
                              description="HTML message shown in ContactList when no contacts are found">{
                (no_contacts) => <UserList
                    tinode={this.props.tinode}
                    connected={this.props.connected}
                    contacts={this.state.contactList}
                    emptyListMessage={no_contacts}
                    topicSelected={this.props.topicSelected}
                    myUserId={this.props.myUserId}
                    showOnline={true}
                    showMode={false}
                    showUnread={false}
                    onTopicSelected={this.props.onTopicSelected}
                    showContextMenu={this.handleContextMenu}
                    onAction={this.handleAction}/>

            }

            </FormattedMessage>

        );
    }
};
