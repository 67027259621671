/* ContactsView holds all contacts-related stuff */
import React from 'react';
import {FormattedMessage, defineMessages} from 'react-intl';

import ContactList from '../widgets/contact-list.jsx';

import {updateFavicon} from '../lib/utils.js';
import HashNavigation from "../lib/navigation";




export default class MyFriends extends React.Component {
    constructor(props) {
        super(props);

        this.handleAction = this.handleAction.bind(this);
        this.handleContextMenu = this.handleContextMenu.bind(this);
        this.handleDoAddFriend = this.handleDoAddFriend.bind(this);


        this.props.tinode.getMeta("me", {"what": "group"}).then(ctrl => {
            console.log("ctrl:", ctrl);
            let list = ctrl.groups;

            for (var i = 0; i < list.length; i++) {
                let item = list[i];
                var itemContact = {
                    public: item.public,
                    comment: item.content,
                    preview: item.content,
                    topic: item.name,
                    topicName: item.name,
                }
                this.state.contactList.push(itemContact)
            }
            this.setState(this.state);
            //this.state.list = addNewFrdReq;
            console.log("addNewFrdReq", this.state.contactList)
        }).catch(err => {
            console.error("Failed subscription to", this.state.topic, err);

        });
        this.state =
            {
                contactList: [],
            }
    }

    static deriveStateFromProps(props) {
        const contacts = [];
        let unreadThreads = 0;
        let archivedCount = 0;

        return {
            contactList: contacts,
            unreadThreads: unreadThreads
        };
    }


    componentDidUpdate(prevProps, prevState) {
        // if (prevProps.chatList != this.props.chatList ||
        //     prevProps.archive != this.props.archive ||
        //     prevProps.blocked != this.props.blocked) {
        //     const newState = NewFrdReqList.deriveStateFromProps(this.props);
        //     this.setState(newState);
        //     if (newState.unreadThreads != prevState.unreadThreads) {
        //         updateFavicon(newState.unreadThreads);
        //     }
        // }
    }

    handleContextMenu(params) {
        console.log("handleContextMenu params:", params)
        // const {formatMessage} = this.props.intl;
        const topic = this.props.tinode.getTopic(params.topicName);
        if (!topic) {
            return;
        }

        const menuItems = [
            {
                title: "同意",
                handler: (params, errorHandler) => {
                    console.log("params", params)
                    this.handleDoAddFriend(params.user, 1)
                    // console.log("agree1 params:",params)
                }
            },
            {
                title: "拒绝",
                handler: (params, errorHandler) => {
                    // console.log("agree1 params:",params)
                    this.handleDoAddFriend(params.user, 2)
                }
            }
        ];


        this.props.showContextMenu({
            topicName: this.props.topic,
            x: params.x,
            y: params.y,
            user: params.topicName
        }, menuItems);
    }

    handleDoAddFriend(uid, status) {
        console.log("handleDoAddFriend uid:" + uid + "  status:" + status)
        const topic = this.props.tinode.getTopic(uid);
        if (topic) {

            topic.setMeta({addFrd: {status: status, uid: uid}, what: "addFrd"}).then(resp => {
                console.log("handleDoAddFriend resp:", resp)
                alert("添加成功")
            })
                .catch(err => {
                    console.log("err:", err)
                });
        }
    }

    handleAction(action_ignored) {
        console.log("handleAction params:",params)
        HashNavigation.navigateTo(HashNavigation.removeUrlParam(window.location.hash, 'tab'));
        this.props.onCreateTopic(params);
    }

    render() {
        return (
            <FormattedMessage id="contacts_not_found"
                              defaultMessage="You have no chats<br />¯∖_(ツ)_/¯"
                              description="HTML message shown in ContactList when no contacts are found">{
                (no_contacts) => <ContactList
                    tinode={this.props.tinode}
                    connected={this.props.connected}
                    contacts={this.state.contactList}
                    emptyListMessage={no_contacts}
                    topicSelected={this.props.topicSelected}
                    myUserId={this.props.myUserId}
                    showOnline={true}
                    showMode={false}
                    showUnread={false}
                    onTopicSelected={this.props.onTopicSelected}
                    showContextMenu={false}
                    onAction={this.handleAction}/>

            }

            </FormattedMessage>

        );
    }
};
